import  { IRequestQuery, request } from './api'
import { IGame, IGroupPlayer, ILeagueGames, ILeagueSeason, IStaff, IStatisticsTeamsInfo } from '@/shared/models/Game'
import { ILeague } from '@/shared/models/League'
import { IGameActionType } from '@/shared/models/GameActionType'
import { IGameSummary } from '@/shared/models/GameSummaryAction'
import { IMessages } from '@/shared/models/Messages'
import { generateTagLabel } from '@/shared/utils/generateTeamName'
import { ICategory } from '@/shared/models/Category'
import { eventPageMessages } from 'components/Event/consts'
import { ILeagueRights } from '@/shared/models/Rights'
import { IEventGallery, IEventNews, IEventVideo } from '@/shared/models/Event'
import { uniqBy } from '@/shared/utils/lodashFunc'
import { sortLeagues } from '@/shared/utils/sorter'

export function getGameStatistics(gameId: number, groupId: number, { clubPrefix }: { clubPrefix: string }): Promise<IStatisticsTeamsInfo> {
    const endpoint = `/scenes/${gameId}/teams/${groupId}/stats`

    return request({
        endpoint,
        method: 'GET',
        clubPrefix,
        query: {
            _with_images_other_clubs: 1
        }
    })
}

export function getGroupRoster(gameId: number, groupId: number, { clubPrefix }: { clubPrefix: string }): Promise<IGroupPlayer[]> {
    return request({
        endpoint: `/scenes/${gameId}/teams/${groupId}/roster/players`,
        method: 'GET',
        clubPrefix
    })
}

export function getGroupStaffRoster(gameId: number, groupId: number, { clubPrefix }: { clubPrefix: string }): Promise<IStaff[]> {
    return request({
        endpoint: `/scenes/${gameId}/teams/${groupId}/roster/staff`,
        method: 'GET',
        clubPrefix
    })
}

export function getGame(gameId: number, { clubPrefix, token = '' }: { clubPrefix: string; token?: string  }): Promise<IGame> {
    return request({
        endpoint: `/scenes/${gameId}`,
        method: 'GET',
        clubPrefix,
        isPrivate: token.length > 0,
        userToken: token
    })
}

export function getLeague(leagueId: number, { clubPrefix }: { clubPrefix: string }): Promise<ILeague> {
    return request({
        endpoint: `/competitions/${leagueId}`,
        method: 'GET',
        clubPrefix
    })
}

export function getLeagues({ clubPrefix }: { clubPrefix: string }): Promise<ILeague[]> {
    return request({
        endpoint: `/competitions`,
        method: 'GET',
        clubPrefix
    })
}

export function getLeagueRights(leagueId: number, { clubPrefix }: { clubPrefix: string }): Promise<ILeagueRights> {
    return request({
        endpoint: `/competitions/${leagueId}/rights`,
        method: 'GET',
        clubPrefix,
        isPrivate: true
    })
}


export function getGameSummary(gameId: number, { clubPrefix }: { clubPrefix: string }): Promise<IGameSummary> {
    return request({
        endpoint: `/scenes/${gameId}/summary`,
        method: 'GET',
        clubPrefix
    })
}
interface IGetGameActionTypesRequest {
    [key: number]: IGameActionType;
}
export function getGameActionTypes(gameId: number, { clubPrefix }: { clubPrefix: string }): Promise<IGetGameActionTypesRequest> {
    return request({
        endpoint: `/scenes/${gameId}/actions`,
        method: 'GET',
        clubPrefix
    })
}

export async function getGameActionTypesAndPlaceholders(gameId: number, { clubPrefix }: { clubPrefix: string }): Promise<{actions: IGameActionType[], placeholders: IMessages}> {
    return getGameActionTypes(gameId, { clubPrefix })
        .then(actions => {
            const actionTypes: IGameActionType[] = Object.values(actions)
            const placeholders: any = actionTypes.reduce((acc, cur) => {
                acc[cur.name] = cur.name
                if (cur.children) {
                    cur.children.forEach(child => {
                        acc[child.name] = child.name
                    })
                }
                return acc
            }, {})
    
            return {
                actions: actionTypes,
                placeholders
            }
        })
}

export function hasGameEditRights(game: IGame, leagueRights?: ILeagueRights | null): boolean {
    const { team1, team2 } = game || {}
    const leagueId = game?.competition ? game?.competition.id : 0
    const isFriendlyGame = !leagueId

    const viewingTypeTeam1 = team1?.rights ? team1.rights.viewing_type : false
    const viewingTypeTeam2 = team2?.rights ? team2.rights.viewing_type : false
    const canEditAtLeastOneTeam = team1.rights ?
        (team1.rights.edit && !(viewingTypeTeam1 === 'opponent') || team2.rights.edit && !(viewingTypeTeam2 ==='opponent'))
        : false

    return isFriendlyGame ? 
        canEditAtLeastOneTeam
        : leagueRights ? (leagueRights?.super || leagueRights?.game || (canEditAtLeastOneTeam && (
                leagueRights?.date || leagueRights?.score || leagueRights?.venue
            )))
            : false
}

export default function getGameTitle(game: IGame, categories: ICategory[], messages: IMessages, intl): string {
    const team1Name = generateTagLabel(game.team1, null, categories, {
        useTranslationsInsteadOfIntl: true,
        translations: messages
    })
    const team2Name = generateTagLabel(game.team2, null, categories, {
        useTranslationsInsteadOfIntl: true,
        translations: messages
    })

    return `${intl.formatMessage(eventPageMessages.game)}: ${team1Name} / ${team2Name}`
}

export function getGameNews(id: number, limit = 10, { clubPrefix }: { clubPrefix: string }): Promise<IEventNews[]> {
    const query = {
        _limit: limit,
        _event: id
    }

    return request({
        endpoint: '/news',
        method: 'GET',
        clubPrefix,
        query
    })
}

export function getGameGalleries(id: number, limit = 10, { clubPrefix }: { clubPrefix: string }): Promise<IEventGallery[]> {
    const query = {
        _limit: limit,
        _event: id
    }

    return request({
        endpoint: '/galleries',
        method: 'GET',
        clubPrefix,
        query
    })
    .then(data => {
        return data.map(item => ({ ...item, type: 'gallery'}))
    })
}

export function getGameVideos(id: number, limit = 10, { clubPrefix }: { clubPrefix: string }): Promise<IEventVideo[]> {
    const query = {
        _limit: limit,
        _event: id
    }

    return request({
        endpoint: '/videos',
        method: 'GET',
        clubPrefix,
        query
    })
    .then(data => {
        return data.map(item => ({ ...item, type: 'video'}))
    })
}

export function getLeagueGames({ leagueId, clubPrefix }: { leagueId: number, clubPrefix: string }): Promise<ILeagueGames> {
    return request({
        endpoint: `/competitions/${leagueId}/scenes/current`,
        clubPrefix
    })
}

export async function getGroupLeagueSeasons({ groupId, clubPrefix, query, withoutSort }: { groupId: number, clubPrefix: string, query?: IRequestQuery, withoutSort?: boolean}): Promise<ILeague[]> {
    let leagueSeasons: ILeagueSeason[] = await request({
        endpoint: `/groups/${groupId}/competitions/seasons`,
        clubPrefix,
        query
    }).catch(() => [])

    leagueSeasons = uniqBy(leagueSeasons, (item) => item.competition.id)
    if (withoutSort) {
        return leagueSeasons.map(e => e.competition)
    }
    return sortLeagues(leagueSeasons.map(e => e.competition))
}

export async function getGroupLeagueGames({ leagueId, groupId, clubPrefix, seasonId }: { leagueId: number, groupId: number, clubPrefix: string, seasonId: number}): Promise<IGame[]> {
    if(groupId) {
        return request({
            endpoint: `/competitions/${leagueId}/seasons/${seasonId}/teams/${groupId}/scenes`,
            clubPrefix,
        })
    }
    return request({
        endpoint: `/competitions/${leagueId}/seasons/${seasonId}/scenes`,
        clubPrefix,
    })
}

export async function getGroupGames({ groupId, clubPrefix, seasonId, isFriendly }: { groupId: number, clubPrefix: string, seasonId: number, isFriendly?: boolean}): Promise<IGame[]> {
    if(!groupId) {
        return []
    }
    return request({
        endpoint: `/scenes`,
        query: {
            _group: groupId,
            _season: seasonId,
            _is_friendly: isFriendly ? 1 : 0
        },
        clubPrefix
    })
}

export function getMultipleGames({ ids, clubPrefix}: { ids: number[], clubPrefix: string }) {
    return request({
        endpoint: `/scenes`,
        query: {
            _scenes: ids
        },
        clubPrefix
    })
}