import { defineMessages } from 'react-intl'

export const GAMESHEET_TAB = 'gamesheet'
export const LINEUP_TAB = 'lineup'
export const MEDIA_TAB = 'media'

export enum GamePageTab {
    GAMESHEET = 'gamesheet',
    LINEUP = 'lineup',
    MEDIA = 'media',
    INDEX = 'index'
}

const imgBaseUrl = `${process.env.NEXT_PUBLIC_CDN}/${process.env.NEXT_PUBLIC_ASSETS_IMAGES}`
export const gamesAndStandingsImageUrl = `${imgBaseUrl}/games-and-standings/`
export const trashIcon = `${imgBaseUrl}/steps/trash_icon.svg`
export const addIcon = `${imgBaseUrl}/dashboard/add.svg`
export const editIcon = `${imgBaseUrl}/steps/edit_icon.svg`

export const eventPageMessages = defineMessages({
    'event.page.title.date': {
        id: 'event_page.title.date',	        
        defaultMessage: 'Date'
        // defaultMessage: '###' //Date
    },
    'event.page.title.time': {
        id: 'event_page.title.time',	        
        defaultMessage: 'Time'
        // defaultMessage: '###' //Time
    },
    'event.page.title.league': {
        id: 'event_page.title.league',	        
        defaultMessage: 'League'
        // defaultMessage: '###' //League
    },
    'event.page.title.matchday': {
        id: 'event_page.title.matchday',	        
        defaultMessage: 'Matchday'
        // defaultMessage: '###' //Matchday
    },
    'event.page.title.venue': {
        id: 'event_page.title.venue',	        
        defaultMessage: 'Venue'
        // defaultMessage: '###' //Venue
    },
    'event.page.title.referees': {
        id: 'event_page.title.referees',	        
        defaultMessage: 'Referees'
        // defaultMessage: '###' //Referees
    },
    'event.page.action.invite': {
        id: 'event_page.action.invite',	        
        defaultMessage: 'Invite'
        // defaultMessage: '###' //Invite
    },
    'event.page.title.officials': {
        id: 'event_page.title.officials',	        
        defaultMessage: 'Officials'
        // defaultMessage: '###' //Officials
    },
    'event.page.menu.details': {
        id: 'event_page.menu.details',	        
        defaultMessage: 'Details'
        // defaultMessage: '###' //Details
    },
    'event.page.menu.gamesheet': {
        id: 'event_page.menu.gamesheet',	        
        defaultMessage: 'Gamesheet'
        // defaultMessage: '###' //Gamesheet
    },
    'event.page.menu.line_up': {
        id: 'event_page.menu.line_up',	        
        defaultMessage: 'Line up'
        // defaultMessage: '###' //Line up
    },
    'event.page.menu.media': {
        id: 'event_page.menu.media',	        
        defaultMessage: 'Media'
        // defaultMessage: '###' //Media
    },
    'event.page.title.periods': {
        id: 'event_page.title.periods',	        
        defaultMessage: 'Periods'
        // defaultMessage: '###' //'Periods'
    },
    'event.page.acronym.overtime': {
        id: 'event_page.acronym.overtime',	        
        defaultMessage: 'OT'
        // defaultMessage: '###' //'OT'
    },
    'event.page.acronym.penalty.shots': {
        id: 'event_page.acronym.penalty.shots',	        
        defaultMessage: 'PS'
        // defaultMessage: '###' //'PS'
    },
    'event.page.title.substitutes': {
        id: 'event_page.title.substitutes',
        defaultMessage: 'Substitutes'
        // defaultMessage: '###' //'Substitutes'
    },
    'event.page.title.documents': {
        id: 'event_page.title.documents',	        
        defaultMessage: 'Documents'
        // defaultMessage: '###' //Documents
    },
    'event.page.title.informations': {
        id: 'event_page.title.informations',	        
        defaultMessage: 'Informations'
        // defaultMessage: '###' //Informations
    },
    'event.page.title.groups': {
        id: 'event_page.title.groups',
        defaultMessage: 'Groups'
        // defaultMessage: '###' //Groups
    },
    'event.page.title.tags': {
        id: 'event_page.title.tags',
        defaultMessage: 'Tags'
        // defaultMessage: '###' //Tags
    },
    'gamesheet.no.information.added.message': {
        id: 'v3_gamesheet.no.information.added.message',	        
        defaultMessage: '###' //No information added yet
    },
    'gamesheet.action.field.goal.by': {
        id: 'v3_gamesheet.action.field.goal.by',	        
        defaultMessage: '###' //Goal by
    },
    'gamesheet.action.field.assist.by': {
        id: 'v3_gamesheet.action.field.assist.by',	        
        defaultMessage: '###' //Assist by
    },
    'gamesheet.action.field.and.assist.by': {
        id: 'v3_gamesheet.action.field.and.assist.by',	        
        defaultMessage: '###' //And assist by
    },
    'gamesheet.action.field.player.out': {
        id: 'v3_gamesheet.action.field.player.out',	        
        defaultMessage: '###' //Player out
    },
    'gamesheet.action.field.player.in': {
        id: 'v3_gamesheet.action.field.player.in',	        
        defaultMessage: '###' //Player in
    },
    'gamesheet.action.field.suspension.time': {
        id: 'v3_gamesheet.action.field.suspension.time',	        
        defaultMessage: '###' //Suspension time (in minutes)
    },
    player: {
        id: 'v3_gamesandstandings.player',
        defaultMessage: '###'
        // defaultMessage: 'Player'
    },
    'gamesheet.title.staff': {
        id: 'v3_gamesheet.title.staff',	        
        defaultMessage: '###' //Staff
    },
    'gamesheet.title.team.captain': {
        id: 'v3_gamesheet.title.team.captain',	        
        defaultMessage: '###' //Team captain
    },
    game: {
        id: 'v3_steps.game',
        defaultMessage: 'Game'
    },
    'event.page.title.video': {
        id: 'event_page.title.video',
        defaultMessage: 'Video'
        // defaultMessage: '###' //'Substitutes'
    },
    'event.page.title.gallery': {
        id: 'event_page.title.gallery',
        defaultMessage: 'Gallery'
        // defaultMessage: '###' //'Substitutes'
    },
    'event.page.title.news': {
        id: 'event_page.title.news',
        defaultMessage: 'News'
        // defaultMessage: '###' //'Substitutes'
    },
    'event.page.show.direction': {
        id: 'event_page.show.direction',
        defaultMessage: 'Show me direction'
        // defaultMessage: '###' //'Show me direction'
    },
    'event.page.btn.back.to.calendar': {
        id: 'event_page.btn.back.to.calendar',
        defaultMessage: '###'
        // defaultMessage: '###' //'Go back to Calendar'        
    },
    'gamesheet.player.position': {
        id: 'v3_gamesheet.player.position',	        
        defaultMessage: '###' //P
    },
    'event.page.title.organizer': {
        id: 'event_page.title.organizer',	        
        defaultMessage: 'Organizer',
        // defaultMessage: '###' //Organizer
    },
    'event.page.live_stream': {
        id: 'event_page.live_stream',	        
        defaultMessage: 'Live stream',
        // defaultMessage: '###' //Live stream
    },
    'event.page.live': {
        id: 'event_page.live',	        
        defaultMessage: 'Live',
        // defaultMessage: '###' //Live
    },
    'event.page.replay': {
        id: 'event_page.replay',	        
        defaultMessage: 'Replay',
        // defaultMessage: '###' //Replay
    }
})
