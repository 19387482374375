import { defineMessages } from 'react-intl'

const MALE = 'gender.male'
const FEMALE = 'gender.female'
const MIXED = 'gender.mixed'
const ANY = 'gender.any'

export const messages = defineMessages({ //eslint-disable-line no-unused-vars
    [MALE]: {
        id: 'gender.male',
        defaultMessage: 'Male'
    },
    [FEMALE]: {
        id: 'gender.female',
        defaultMessage: 'Female'
    },
    [MIXED]: {
        id: 'gender.mixed',
        defaultMessage: 'Mixed'
    },
    [ANY]: {
        id: 'gender.any',
        defaultMessage: 'Any'
    }
})

export const genders = [
    {
        id: 0,
        name: messages[MALE],
        short: 'M',
        value: 0
    },
    {
        id: 1,
        name: messages[FEMALE],
        short: 'F',
        value: 1
    }
]

export const gendersWithMixed = [
    ...genders,
    {
        id: 2,
        name: messages[MIXED],
        short: 'M/F',
        value: 2
    }
]

export const gendersWithAny = [
    {
        id: 3,
        name: messages[ANY],
        short: '-',
        value: 3
    },
    ...genders,
]

export const indexGenderMap = {
    0: 'male',
    1: 'female',
    2: 'mixed'
}
export const genderIndexMap = {
    male: 0,
    female: 1,
    mixed: 2
}