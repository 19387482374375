import { useClub } from 'contexts/club'
import { useIntl } from 'react-intl'
import { ICategory } from '../models/Category'
import { IGroup } from '../models/Group'
import { ISeason } from '../models/Season'
import { gendersWithMixed } from './genderConst'


export const getGroupCategoryGenderPartName = (
    groupCategoryName?: string,
    groupGenderTitle?: string,
    opts?: {
        noBrackets?: boolean,
        useTranslationsInsteadOfIntl?: boolean,
        translations?: any
    }
): string => {
    const intl = useIntl()
    let partName = ''
    const { noBrackets, useTranslationsInsteadOfIntl, translations } = opts || {}
    
    if (groupCategoryName) {
        if (useTranslationsInsteadOfIntl) {
            partName = `${translations[groupCategoryName]}${groupGenderTitle ? ' ': ''}`    
        } else {
            partName = `${intl.formatMessage({ id: groupCategoryName, defaultMessage: groupCategoryName })}${groupGenderTitle ? ' ': ''}`
        }
    }
    if (groupGenderTitle) {
        partName += groupGenderTitle
    }

    if (!noBrackets && (groupCategoryName || groupGenderTitle)) {
        partName = `(${partName})`
    }

    return partName
}

export const generateTagLabel = (
    group: IGroup,
    season: ISeason,
    categories: ICategory[],
    opts?: {
        onlyCategoryGenderPart?: boolean;
        noBrackets?: boolean;
        useTranslationsInsteadOfIntl?: boolean;
        translations?: any;
    }): string => {
    const { club } = useClub()
    const groupName = group?.name || ''
    const groupSubName = group?.subname || ''
    const category = group?.category || ''
    const groupGender = group?.gender || group?.gender === 0 ? group.gender : ''
    const groupGenderTitle = groupGender || groupGender === 0 ? gendersWithMixed[groupGender].short : ''
    const groupSeasonName = season && season.name ? season.name : ''
    const groupCategory: any = category ? categories.find(({ id }) => id === category.id) : {}
    let groupCategoryName = groupCategory && groupCategory.name ? groupCategory.name : 0
    if (!groupCategoryName) {
        groupCategoryName = category && category.name ? category.name : 0
    }
    if (opts.onlyCategoryGenderPart) {
        return getGroupCategoryGenderPartName(groupCategoryName, groupGenderTitle, opts)
    }


    return `${groupName}${club.show_team_additional_info && (groupCategoryName || groupGenderTitle) ?
            `${groupSubName ? ` ${groupSubName}` : ''} ${getGroupCategoryGenderPartName(groupCategoryName, groupGenderTitle, opts)}`
            : ''}${groupSeasonName ? ` - ${groupSeasonName}` : ''}`
}