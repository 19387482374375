export function compare(a: number, b: number): number {
    if (a < b) {
        return -1;
    }
    if (a > b) {
        return 1;
    }

    return 0;
}

const mainSorter = (a, b) => {
    if (a.ismain) {
        return -1
    }
    if (b.ismain) {
        return 1
    }

    return 0
}

const teamSorter = (a, b) => {
    if (a.is_team) {
        if (b.is_team) {
            return 0
        } else {
            return -1
        }
    } else {
        if (b.is_team) {
            return 1
        } else {
            return 0
        }
    }
}

const nameSorter = (a, b) => {
    if (a.name?.toLowerCase() < b.name?.toLowerCase()) {
        return -1
    }
    if (b.name?.toLowerCase() < a.name?.toLowerCase()) {
        return 1
    }

    return 0
}

// NOTE: we use the category ID and not the name
// because the sorting is not truly alphabetical here.
// E.g. we want Senior > U20 > U10 > U8
const categorySorter = (a, b) => {
    if (a.category) {
        if (b.category) {
            if (a.category.id < b.category.id) {
                return -1
            }

            if (a.category.id > b.category.id) {
                return 1
            }
        } else {
            return 1
        }
    } else if (b.category) {
        return -1
    }

    return 0
}

const genderSorter = (a, b) => {
    if (a.gender < b.gender) {
        return -1
    }

    if (a.gender > b.gender) {
        return 1
    }

    return 0
}

const subnameSorter = (a, b) => {
    if (a.subname?.toLowerCase() < b.subname?.toLowerCase()) {
        return -1
    }

    if (a.subname?.toLowerCase() > b.subname?.toLowerCase()) {
        return 1
    }

    return 0
}

const groupSorter = (a, b) => {
    return mainSorter(a, b) || teamSorter(a, b) || categorySorter(a, b) || nameSorter(a, b) || genderSorter(a, b) || subnameSorter(a, b)
}


export const sortGroups = groups => {
    return [...groups].sort(groupSorter)
}

export const sortLeagues = (leagues) => {
    return [...leagues].sort(nameSorter)
}

export const sortCalendarEventsByStartDate = events => {
    return [...events].sort(function(a, b) {
        return new Date(a.start_date).getTime() - new Date(b.start_date).getTime()
    })
}